import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import logo from "images/logo.png";
import config from "../../config/config";
import {useAuthState} from "../../context";
import Table from "../../components/table/Table";
import Avatar from "react-avatar-edit";
import UserDefaultPhoto from "../../components/misc/UserDefaultPhoto";

export default (props) => {

  const [lessons, setLessons] = useState([]);
  const [currentUser, setCurrentUser] = useState(0);
  const [filter, setFilter] = useState('');

  const userDetails = useAuthState();


  //  Redirect if already logged in
  useEffect(() => {
    const fetchData = async () => {

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userDetails.tokens.access.token}`
        },
      };

      let response = await fetch(`${config.API_BASE_URL}/lessons/list?limit=1000&sortBy=createdAt:desc`, requestOptions);
      let data = await response.json();
      console.log(data);
      if (data.results) setLessons(data.results);
    }
    fetchData();
  }, []);

  return <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex={0} data-todo-x-data=""
               data-todo-x-init="$el.focus()">
    {/* Page title & actions */}
    <div className="px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
      <div className="flex-1 min-w-0">
        <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
          Lesson History
        </h1>
      </div>
    </div>


    {/* Projects list (only on smallest breakpoint) */}
    <div className="mt-10 sm:hidden">
      <div className="px-4 sm:px-6">
        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">Lessons</h2>
      </div>
      <ul className="mt-3 border-t border-gray-200 divide-y divide-gray-100" data-todo-x-max="1">

        <li>
          <a href="#" className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
                  <span className="flex items-center truncate space-x-3">
                    <span className="w-2.5 h-2.5 flex-shrink-0 rounded-full bg-pink-600" aria-hidden="true"></span>
                    <span className="font-medium truncate text-sm leading-6">
                      Ongoing lesson
                      {' '}
                      <span className="truncate font-normal text-gray-500">General English</span>
                    </span>
                  </span>
            <svg className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                 data-todo-x-description="Heroicon name: solid/chevron-right" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"></path>
            </svg>
          </a>
        </li>

        <li>
          <a href="#" className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6">
                  <span className="flex items-center truncate space-x-3">
                    <span className="w-2.5 h-2.5 flex-shrink-0 rounded-full bg-purple-600" aria-hidden="true"></span>
                    <span className="font-medium truncate text-sm leading-6">
                      Finished lesson
                      {' '}
                      <span className="truncate font-normal text-gray-500">General English</span>
                    </span>
                  </span>
            <svg className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                 data-todo-x-description="Heroicon name: solid/chevron-right" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"></path>
            </svg>
          </a>
        </li>

      </ul>
    </div>

    {/* Projects table (small breakpoint and up) */}
    <div className="hidden sm:block">
      <div className="align-middle inline-block min-w-full border-b border-gray-200">
        <table className="min-w-full">
          <thead>
          <tr className="border-t border-gray-200">
            <th
              className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <span className="lg:pl-2">Lesson Id</span>
            </th>
            <th
              className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <span className="lg:pl-2">Status</span>
            </th>
            <th
              className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Participants
            </th>
            <th
              className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Payout
            </th>
            <th
              className="hidden md:table-cell px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Time
            </th>
            <th
              className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
          </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-100" data-todo-x-max="1">
          {lessons.map((lesson, index) => {
            let createdAt = lesson.createdAt && new Date(lesson.createdAt);
             let finishedAt = lesson.finishedAt && new Date(lesson.finishedAt);
            let timeDiff = createdAt && finishedAt ? Math.ceil((finishedAt.getTime() - createdAt.getTime())/1000/60) : 0;
            return (
              <tr key={index}>
                <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                  <div className="flex items-center space-x-3 lg:pl-2">
                    <div
                      className={lesson.finishedAt ? "flex-shrink-0 w-2.5 h-2.5 rounded-full bg-green-600" : "flex-shrink-0 w-2.5 h-2.5 rounded-full bg-yellow-600"}
                      aria-hidden="true"></div>
                    <a href="#" className="truncate hover:text-gray-600">
                            <span>
                              {lesson.id.substr(-6)}
                              {' '}
                              <span className="text-gray-500 font-normal">{lesson?.category?.title}</span>
                            </span>
                    </a>
                  </div>
                </td>
                <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900">
                  <div className="flex items-center space-x-3 lg:pl-2">
                     <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">
                    {lesson.status || 'Unknown'} </span>
                  </div>
                </td>
                <td className="px-6 py-3 text-sm text-gray-500 font-medium">
                  {lesson.student && <div className="flex items-center space-x-3 lg:pl-2">
                    <UserDefaultPhoto className="inline-block h-6 w-6 rounded-full"/>
                    <span>{lesson.student.firstName || 'Unknown'} {lesson.student.lastName}
                            </span>
                  </div>}
                  {lesson.teacher && <div className="flex items-center space-x-3 lg:pl-2">
                    {lesson.teacher.imgUrl ? <img className="max-w-none h-6 w-6 rounded-full ring-2 ring-white"
                                                  src={lesson.teacher.imgUrl}
                                                  alt=""/> :
                      <UserDefaultPhoto className="inline-block h-6 w-6 rounded-full"/>}


                    <span>{lesson.teacher.firstName || 'Unknown'} {lesson.teacher.lastName}
                            </span>
                  </div>}
                </td>
                <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                   {timeDiff} min, ${parseFloat(lesson.amountUSD || 0).toFixed(2)}
                </td>
                <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-right">
                  <p>{new Date(lesson.createdAt).toISOString().slice(5, 16).replace('T', ' ')}</p>
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </div>
  </main>
};

