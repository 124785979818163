import React, {useState} from "react";
import tw from "twin.macro";
import CreatableSelect from 'react-select/creatable';
import Avatar from 'react-avatar-edit'
import ModalCentered from "../modals/ModalCentered";
import {b64toFormData, uploadFile} from '../../services/upload';
import {useAuthState} from "../../context";
import Alert from "../misc/Alert";
import UserDefaultPhoto from "../misc/UserDefaultPhoto";

export default ({onSelect, imgUrl}) => {
  const userDetails = useAuthState();
  const [alert, setAlert] = useState('');

  const [isOpened, setIsOpened] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [state, setState] = useState({
    preview: imgUrl,
  });

  const onClose = async () => {
    if (!state.isLoaded) {
      setIsOpened(false);
      return
    }

    setIsProcessing(true);
    try {
      const s3Url = await uploadFile(await b64toFormData(state.preview, `avatar.png`), userDetails);
      onSelect(s3Url);
      setIsOpened(false);
    } catch (err) {
      setAlert(err.message);
    }
    setIsProcessing(false);
  }


  const onCrop = async (preview) => {
    setState(p => ({...p, preview, isLoaded: true}))
  }


  return <>
    <div className="sm:col-span-6">
      <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
        Photo*
      </label>
      <div className="mt-2 flex items-center">
        <span className="h-24 w-24 rounded-full overflow-hidden bg-gray-100">
                             {!state.preview ?
                               <UserDefaultPhoto className="h-full w-full text-gray-300"/> : <img src={state.preview}/>}
                            </span>
        <button
          onClick={() => setIsOpened(true)}
          type="button"
          className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Upload
        </button>
      </div>
    </div>
    {isOpened && <ModalCentered onClose={onClose}>
      {alert && <Alert errorText={alert}/>}
      <Avatar
        exportAsSquare={true}
        exportSize={640}
        width={340}
        height={295}
        onCrop={onCrop}
        onClose={onClose}
      />
      <div className="mt-5 sm:mt-6">
        <button
          onClick={onClose}
          data-todo-at-click="open = false; setTimeout(() => open = true, 1000)"
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
          {isProcessing &&
          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
               fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>}
          {state.isLoaded ? 'Save' : 'Cancel'}
        </button>
      </div>
    </ModalCentered>}
  </>

};
