import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import EmailIllustrationSrc from "../../images/email-illustration.svg";

export default () => {

  return <FormContainer>
    <div className="space-y-8">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 text-center mb-5">
          Awaiting Approval
        </h3>
        <p className="mt-1 text-sm text-gray-500 text-center">
          Your profile is under review. We will contact you after approval.
        </p>
      </div>
      <div className="space-y-6">
        <img className="mx-auto h-40 w-50 rounded-full xl:w-56 xl:h-56"
             src={EmailIllustrationSrc}
             alt=""/>
      </div>
    </div>
  </FormContainer>

};

const FormContainer = tw.div`w-full flex-1 mt-8`;
