import React, {useEffect, useState} from "react";
import config from "../../config/config";
import {useAuthState} from "../../context";

export default (props) => {

  const [transactions, setTransactions] = useState([]);

  const userDetails = useAuthState();


  //  Redirect if already logged in
  useEffect(() => {
    const fetchData = async () => {

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userDetails.tokens.access.token}`
        },
      };

      let response = await fetch(`${config.API_BASE_URL}/transactions/list`, requestOptions).then();
      let data = await response.json();
      console.log('transactions',data);
      if (data.results) setTransactions(data.results);
    }
    fetchData();
  }, []);

  return <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
    <div className="mt-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3" data-todo-x-max="1">
          {/* Card */}

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-gray-400" data-todo-x-description="Heroicon name: outline/scale"
                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                       aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path>
                  </svg>
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      Balance
                    </dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">
                        $0
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              {/*<div className="text-sm">*/}
              {/*  <a href="/" className="font-medium text-cyan-700 hover:text-cyan-900">*/}
              {/*    View all*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-gray-400" data-todo-x-description="Heroicon name: outline/refresh"
                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                       aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
                  </svg>
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      Pending
                    </dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">
                        $0
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              {/*<div className="text-sm">*/}
              {/*  <a href="/" className="font-medium text-cyan-700 hover:text-cyan-900">*/}
              {/*    View all*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-gray-400" data-todo-x-description="Heroicon name: outline/check-circle"
                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                       aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      Processed (last 30 days)
                    </dt>
                    <dd>
                      <div className="text-lg font-medium text-gray-900">
                        $0
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-5 py-3">
              {/*<div className="text-sm">*/}
              {/*  <a href="/" className="font-medium text-cyan-700 hover:text-cyan-900">*/}
              {/*    View all*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>

        </div>
      </div>

      <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
        Recent activity
      </h2>

      {/* Activity list (smallest breakopoint only) */}
      <div className="shadow sm:hidden">
        <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden" data-todo-x-max="1">

          {transactions.map((trans, index) => (<li key={index}>
            <a href="/" className="block px-4 py-4 bg-white hover:bg-gray-50">
                    <span className="flex items-center space-x-4">
                      <span className="flex-1 flex space-x-2 truncate">
                        <svg className="flex-shrink-0 h-5 w-5 text-gray-400"
                             data-todo-x-description="Heroicon name: solid/cash" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fillRule="evenodd"
        d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
        clipRule="evenodd"></path>
</svg>
                        <span className="flex flex-col text-gray-500 text-sm truncate">
                          <span className="truncate">Payment to Molly Sanders</span>
                          <span><span className="text-gray-900 font-medium">$20,000</span> USD</span>
                          <span>July 11, 2020</span>
                        </span>
                      </span>
                      <svg className="flex-shrink-0 h-5 w-5 text-gray-400"
                           data-todo-x-description="Heroicon name: solid/chevron-right"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                           aria-hidden="true">
  <path fillRule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clipRule="evenodd"></path>
</svg>
                    </span>
            </a>
          </li>))}

        </ul>

        <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
             aria-label="Pagination">
          <div className="flex-1 flex justify-between">
            <a href="/"
               className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
              Previous
            </a>
            <a href="/"
               className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
              Next
            </a>
          </div>
        </nav>
      </div>

      {/* Activity table (small breakopoint and up) */}
      <div className="hidden sm:block">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                  <th
                    className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Transaction
                  </th>
                  <th
                    className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th
                    className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                    Status
                  </th>
                  <th
                    className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200" data-todo-x-max="1">

                {transactions.map((trans, index) => (
                <tr key={index} className="bg-white">
                  <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <div className="flex">
                      <a href="/" className="group inline-flex space-x-2 truncate text-sm">
                        <svg className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                             data-todo-x-description="Heroicon name: solid/cash" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd"
                                d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                                clipRule="evenodd"></path>
                        </svg>
                        <p className="text-gray-500 truncate group-hover:text-gray-900">
                          Payment to Molly Sanders
                        </p>
                      </a>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                    <span className="text-gray-900 font-medium">$20,000 </span>
                    USD
                  </td>
                  <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
                            <span
                              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">
                              success
                            </span>
                  </td>
                  <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                    July 11, 2020
                  </td>
                </tr>))}


                </tbody>
              </table>
              {/* Pagination */}
              {/*<nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">*/}
              {/*  <div className="hidden sm:block">*/}
              {/*    <p className="text-sm text-gray-700">*/}
              {/*      Showing*/}
              {/*      <span className="font-medium">1</span>*/}
              {/*      to*/}
              {/*      <span className="font-medium">10</span>*/}
              {/*      of*/}
              {/*      <span className="font-medium">20</span>*/}
              {/*      results*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*  <div className="flex-1 flex justify-between sm:justify-end">*/}
              {/*    <a href="/" className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">*/}
              {/*      Previous*/}
              {/*    </a>*/}
              {/*    <a href="/" className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">*/}
              {/*      Next*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*</nav>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
};

