import config from "../config/config";

const signFile = async (file, user) => {
  console.log(file);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.tokens.access.token}`
    },
    body: JSON.stringify({fileType: file.type, fileName: file.name}),
  };

  try {
    let response = await fetch(`${config.API_BASE_URL}/files/sign`, requestOptions);
    const json = await response.json()
    if (!response.ok)
      throw new Error(json.message || response.statusText);
    return json;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const uploadFile = async (file, user) => {
  try {
    const {signedRequest, url} = await signFile(file, user);
    const response = await fetch(
      signedRequest,
      {
        method: 'PUT',
        body: file,
      });
    if (!response.ok)
      throw new Error(response.statusText);

    return url;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const b64toFormData = (base64, filename) =>
  fetch(base64)
    .then(res => res.blob())
    .then(res => {
      const formData = new FormData();
      formData.append('upload', res, filename)
      return formData.get('upload');
    })

export {
  uploadFile,
  b64toFormData
};