import React, {useState} from "react";
import logo from "images/logo.png";
import {logout, useAuthDispatch, useAuthState} from "../../context";
import UserDefaultPhoto from "../../components/misc/UserDefaultPhoto";
import {
  isDesktop
} from "react-device-detect";
import {Link} from "react-router-dom";


const menuItems = [
  {
    title: 'Dashboard',
  icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>,
  href: '/manage'
  },
  {
    title: 'Lessons',
    icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>,
    href: '/manage/lessons'
  },
  {
    title: 'Teachers',
    icon: <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>,
    href: '/manage/teachers'
  },
]
export default (props) => {
  const [isOpen, setIsOpen] = useState(isDesktop);
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch()

  const [activeId, setActiveId] = useState(0);

  console.log(activeId);
  return <>
    <div className="h-screen flex overflow-hidden bg-white" data-todo-x-data="{ sidebarOpen: false }"
         data-todo-x-init="$watch('sidebarOpen', value => { if (value) { $nextTick(() => $refs.sidebar.focus()) } })"
         data-todo-at-keydown-window-escape="sidebarOpen = false">
      {isOpen && <>
        <div data-todo-x-show="sidebarOpen" className="lg:hidden"
             data-todo-x-description="Off-canvas menu for mobile, show/hide based on off-canvas menu state.">
          <div className="fixed inset-0 flex z-40">
            <div data-todo-at-click="sidebarOpen = false" data-todo-x-show="sidebarOpen"
                 onClick={() => setIsOpen(false)}
                 data-todo-x-description="Off-canvas menu overlay, show/hide based on off-canvas menu state."
                 data-todo-x-transition-enter="transition-opacity ease-linear duration-300"
                 data-todo-x-transition-enter-start="opacity-0" data-todo-x-transition-enter-end="opacity-100"
                 data-todo-x-transition-leave="transition-opacity ease-linear duration-300"
                 data-todo-x-transition-leave-start="opacity-100" data-todo-x-transition-leave-end="opacity-0"
                 className="fixed inset-0">
              <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>
            <div data-todo-x-show="sidebarOpen" data-todo-x-ref="sidebar" tabIndex={0}
                 data-todo-x-description="Off-canvas menu, show/hide based on off-canvas menu state."
                 data-todo-x-transition-enter="transition ease-in-out duration-300 transform"
                 data-todo-x-transition-enter-start="-translate-x-full" data-todo-x-transition-enter-end="translate-x-0"
                 data-todo-x-transition-leave="transition ease-in-out duration-300 transform"
                 data-todo-x-transition-leave-start="translate-x-0" data-todo-x-transition-leave-end="-translate-x-full"
                 className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  onClick={() => setIsOpen(false)}
                  type="button" data-todo-x-show="sidebarOpen" data-todo-at-click="sidebarOpen = false"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Close sidebar</span>
                  <svg className="h-6 w-6 text-white" data-todo-x-description="Heroicon name: outline/x"
                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                       aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img className="h-8 w-auto" src={logo} alt="WellSpeak"/>
                  <p className="mx-2" style={{fontFamily: 'PT Serif Caption'}}>WellSpeak</p>
                </div>
                <nav aria-label="Sidebar" className="mt-5">
                  <div className="px-2 space-y-1">
                    {
                      menuItems.map((item, index) => (
                        <Link to={item.href}
                              key={index}
                           onClick={() => setActiveId(index)}
                           className={index === activeId ? "bg-gray-100 text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"}>
                          <svg className={index === activeId ? "text-gray-500 mr-4 h-6 w-6" : "text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6"} data-todo-x-description="Heroicon name: outline/home"
                               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                               aria-hidden="true">
                            {item.icon}
                          </svg>
                          {item.title}
                        </Link>
                      ))
                    }
                  </div>
                  <hr className="border-t border-gray-200 my-5" aria-hidden="true"/>
                  <div className="px-2 space-y-1">
                    <a href="/manage"
                       className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md">
                      <svg className="text-gray-400 group-hover:text-gray-500 mr-4 h-6 w-6"
                           data-todo-x-description="Heroicon name: outline/cog" xmlns="http://www.w3.org/2000/svg"
                           fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                      </svg>
                      Settings
                    </a>

                  </div>
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                  <div className="flex items-center">
                    <div>
                      {userDetails.user.imgUrl ? <img className="inline-block h-10 w-10 rounded-full"
                           src={userDetails.user.imgUrl}
                           alt=""/> : <UserDefaultPhoto className="inline-block h-9 w-9 rounded-full"/>}
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                        {userDetails.user.firstName || 'Unknown'} {userDetails.user.lastName || 'User'}
                      </p>
                      <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                        <a
                          onClick={() => logout(dispatch)}
                          href="#"
                          className="text-xs font-medium text-gray-500 group-hover:text-gray-700"
                        >
                          Log out
                        </a>
                      </p>
                    </div>
                  </div>

              </div>
            </div>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </div>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col h-0 flex-1 border-r border-gray-200 bg-gray-100">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                  <img className="h-8 w-auto" src={logo} alt="WellSpeak"/>
                  <p className="mx-2" style={{fontFamily: 'PT Serif Caption'}}>WellSpeak</p>
                </div>
                <nav className="mt-5 flex-1" aria-label="Sidebar">
                  <div className="px-2 space-y-1">
                    {
                      menuItems.map((item, index) => (
                        <Link to={item.href}
                              key={index}
                              onClick={() => setActiveId(index)}
                           className={index === activeId ? "bg-gray-200 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"}>
                          <svg className={index === activeId ? "bg-gray-200 text-gray-900 group-hover:text-gray-500 mr-3 h-6 w-6" : "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"}
                               data-todo-x-description="Heroicon name: outline/Lessons" xmlns="http://www.w3.org/2000/svg"
                               fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            {item.icon}
                          </svg>
                          {item.title}
                        </Link>
                      ))
                    }
                  </div>
                  <hr className="border-t border-gray-200 my-5" aria-hidden="true"/>
                  <div className="flex-1 px-2 space-y-1">


                    <a href="/manage"
                       className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
                      <svg className="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                           data-todo-x-description="Heroicon name: outline/cog" xmlns="http://www.w3.org/2000/svg"
                           fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                      </svg>
                      Settings
                    </a>

                  </div>
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <div className="flex-shrink-0 w-full group block">
                  <div className="flex items-center">
                    <div>
                      {userDetails.user.imgUrl ? <img className="inline-block h-9 w-9 rounded-full"
                                                      src={userDetails.user.imgUrl}
                                                      alt=""/> :
                        <UserDefaultPhoto className="inline-block h-9 w-9 rounded-full"/>}
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                        {userDetails.user.firstName || 'Unknown'} {userDetails.user.lastName || 'User'}
                      </p>
                      <a
                        onClick={() => logout(dispatch)}
                        href="#"
                        className="text-xs font-medium text-gray-500 group-hover:text-gray-700"
                      >
                        Log out
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="lg:hidden">
          <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
            <div>
              <img className="h-8 w-auto" src={logo} alt="Workflow"/>
            </div>
            <div>
              <button type="button" data-todo-at-click-stop="sidebarOpen = true"
                      onClick={() => setIsOpen(true)}
                      className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="h-6 w-6" data-todo-x-description="Heroicon name: outline/menu"
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  </>
};

