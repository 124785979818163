import React, { useState, useReducer } from 'react';

let user = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).user
  : '';

let tokens = localStorage.getItem('currentUser')
  ? JSON.parse(localStorage.getItem('currentUser')).tokens
  : '';

export const initialState = {
  user: '' || user,
  tokens: '' || tokens,
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        loading: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        user: action.payload.user,
        tokens: action.payload.tokens,
        loading: false,
      };
    case 'TOKEN_REFRESH_SUCCESS':
      return {
        ...initialState,
        tokens: action.payload,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        user: '',
        tokens: '',
      };

    case 'LOGIN_ERROR':
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };
    case 'UPDATE_USER':
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };
    case 'UPDATE_SUCCESS':
      return {
        ...initialState,
        user: action.payload,
        loading: false,
      };
    case 'UPDATE_ERROR':
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
