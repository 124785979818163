const nameValidation = (fieldName, fieldValue) => {
  if (fieldValue.trim() === '') {
    return `${fieldName} is required`;
  }
  if (/[^a-zA-Z -]/.test(fieldValue)) {
    return 'Invalid characters';
  }
  if (fieldValue.trim().length < 3) {
    return `${fieldName} needs to be at least three characters`;
  }
  return null;
};

const emailValidation = email => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    )
  ) {
    return null;
  }
  if (email.trim() === '') {
    return 'Email is required';
  }
  return 'Please enter a valid email';
};

const ageValidation = age => {
  if (!age) {
    return 'Age is required';
  }
  if (age < 18) {
    return 'Age must be at least 18';
  }
  if (age > 99) {
    return 'Age must be under 99';
  }
  return null;
};

const isNumber = value => {
  if (isNaN(value)) {
    return 'Value should be a number';
  }
  return null;
}

const password = password => {
  if (password.length < 6) {
    return 'Password should have more than 6 characters';
  }
  if (!password.match(/\d/) || !password.match(/[a-zA-Z]/)) {
    return 'Password must contain at least one letter and one number';
  }
  return null;
};

const minLength = (value, length) => {
  if (value.length < length) {
    return `Value should have more than ${length} characters`;
  }
  return null;
}

const arrayIsFilled = (value) => {
  if (typeof Array.isArray(value) === false || value.length < 1) {
    return `Select an option`;
  }
  return null;
}

const ImageUploaded = (value) => {
  if (value.length < 1) {
    return `Upload an image`;
  }
  return null;
}

export default {
  firstName: name => nameValidation('First Name', name),
  lastName: name => nameValidation('Last Name', name),
  email: emailValidation,
  age: ageValidation,
  password,
  password1: password,
  about: value => minLength(value, 10),
  education: value => minLength(value, 10),
  city: value => minLength(value, 2),
  country: value => minLength(value, 2),
  dialect: value => arrayIsFilled(value),
  imgUrl: value => ImageUploaded(value),
  payExpected: value => isNumber(value),
  trafficSource: value => minLength(value, 3),
  certificates: () => null,
};
