import React, {useCallback, useState} from "react";
import tw from "twin.macro";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import UploadAvatar from "../features/UploadAvatar";
import validation from "../../services/validation";
import {COUNTRIES_LIST} from "../../config/countriesSelect";
import {DIALECTS_LIST} from "../../config/dialectsSelect";
import {loginUser, useAuthState} from "../../context";
import Alert from "../misc/Alert";
import {useDropzone} from 'react-dropzone'
import {uploadFile} from "../../services/upload";


export default ({
                  imgUrl,
                  firstName,
                  lastName,
                  country,
                  city,
                  about,
                  education,
                  dialect,
                  payExpected,
                  trafficSource,
                  certificates,
                  onSubmit: onOuterSubmit
                }) => {
  const userDetails = useAuthState();

  const [values, setValues] = useState({
    imgUrl: imgUrl || '',
    firstName: firstName || '',
    lastName: lastName || '',
    country: country || '',
    city: city || '',
    about: about || '',
    education: education || '',
    dialect: dialect || '',
    payExpected: payExpected || '',
    trafficSource: trafficSource || '',
    certificates:  Array.isArray(certificates) ? certificates : [],
  });

  const [isUploading, setUploading] = useState(false);

  const onDrop = useCallback( async acceptedFiles => {
    setUploading(true);

    let fileUrls = [];

    fileUrls = await Promise.all(acceptedFiles.map(async file => await uploadFile(file, userDetails)));

    console.log(fileUrls);
    setValues(p => ({
      ...p,
      certificates: [...p.certificates, ...fileUrls],
    }));

    setUploading(false);

  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
  })

  const [alert, setAlert] = useState('');

  const [errors, setErrors] = useState({});

  const [touched, setTouched] = React.useState({});

  const handleChange = (e) => {
    const {name, value} = e.target
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }))

    setTouched({
      ...touched,
      [name]: true,
    });
  }

  const handleSelectChange = (value, action) => {
    const val = Array.isArray(value) ? value.map(v => v.value) : value.value;
    handleChange({target: {name: action.name, value: val}});
    handleBlur({target: {name: action.name, value: val}})
  }


  const handleBlur = evt => {
    const {name, value} = evt.target;
    const {[name]: removedError, ...rest} = errors;
    const error = validation[name](value);

    setErrors({
      ...rest,
      ...(error && {[name]: touched[name] && error}),
    });

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let tempErrors = {};
    Object.keys(values).map(name => {
      let validResp = validation[name](values[name]);
      if (validResp) tempErrors[name] = validResp;
    });

    if (Object.values(tempErrors).length > 0) {
      setErrors(tempErrors);
      setAlert('Please check all fields.')
      window.scrollTo(0, 0);
      return;
    } else {
      // TODO: edit
      onOuterSubmit && onOuterSubmit({...values, teacherStatus: 'VIDEO_REQUIRED'});
    }
    ;
  }

  return <FormContainer onSubmit={onSubmit}>
    {alert && <Alert errorText={alert}/>}
    <div className="space-y-8">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Personal Information
        </h3>
        {/*<p className="mt-1 text-sm text-gray-600">*/}
        {/*  Please fill up you profile.*/}
        {/*</p>*/}
      </div>
      <div className="pt-0">
        <UploadAvatar imgUrl={imgUrl} onSelect={(value) => {
          setValues(p => ({...p, imgUrl: value}));
          setErrors(p => ({...p, img: null}))
        }}/>
        {errors['imgUrl'] && <p className="mt-2 text-sm text-red-600">{errors['imgUrl']}</p>}
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
              First name*
            </label>
            <div className="mt-1">
              <Input required value={values.firstName} onBlur={handleBlur}
                     onChange={handleChange} type="text" name="firstName" autoComplete="given-name"
              />
              {errors['firstName'] && <p className="mt-2 text-sm text-red-600">{errors['firstName']}</p>}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              Last name*
            </label>
            <div className="mt-1">
              <Input required value={values.lastName} onBlur={handleBlur}
                     onChange={handleChange} type="text" name="lastName" autoComplete="family-name"
              />
              {errors['lastName'] && <p className="mt-2 text-sm text-red-600">{errors['lastName']}</p>}
            </div>
          </div>

        </div>
      </div>

      <div className="pt-8">
        <fieldset>
          <legend className="text-base font-medium text-gray-900">
            I live in
          </legend>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Country / Region*
              </label>
              <div className="mt-1">
                <Select name="country"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
                        options={COUNTRIES_LIST}
                        onChange={handleSelectChange}
                        value={COUNTRIES_LIST.find(obj => obj.value === values.country)}
                />
                {errors['country'] && <p className="mt-2 text-sm text-red-600">{errors['country']}</p>}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                City*
              </label>
              <div className="mt-1">
                <Input required value={values.city} onBlur={handleBlur}
                       onChange={handleChange} type="text" name="city"
                />
                {errors['city'] && <p className="mt-2 text-sm text-red-600">{errors['city']}</p>}
              </div>
            </div>
          </div>

        </fieldset>

      </div>

      <div className="pt-4">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Profile
          </h3>
          <p className="mt-1 text-sm text-gray-600">
            Students will view this information on your profile to decide if you're a good fit for them.
          </p>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="about" className="block text-sm font-medium text-gray-700">
              About*
            </label>
            <div className="mt-1">
                        <textarea onBlur={handleBlur}
                                  onChange={handleChange} name="about" rows="3"
                                  defaultValue={values.about}
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"/>
              {errors['about'] && <p className="mt-2 text-sm text-red-600">{errors['about']}</p>}
            </div>
            <p className="mt-2 text-sm text-gray-600">Write a few sentences about yourself.</p>
          </div>

          <div className="sm:col-span-6">
            <label htmlFor="education" className="block text-sm font-medium text-gray-700">
              Education*
            </label>
            <div className="mt-1">
                        <textarea required onBlur={handleBlur}
                                  onChange={handleChange} name="education" rows="3"
                                  defaultValue={values.education}
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"/>
              {errors['education'] && <p className="mt-2 text-sm text-red-600">{errors['education']}</p>}
            </div>
          </div>

          <div className="sm:col-span-6">
            <fieldset>
              <legend className="text-base font-medium text-gray-900">
                Some details about me
              </legend>
            </fieldset>

          </div>

          <div className="sm:col-span-3">
            <label htmlFor="dialect" className="block text-sm font-medium text-gray-700">
              My English dialect*
            </label>
            <div className="mt-1">
              <Select name="dialect"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
                      options={DIALECTS_LIST}
                      onChange={handleSelectChange}
                      value={DIALECTS_LIST.find(obj => obj.value === values.dialect)}

              />
              {errors['dialect'] && <p className="mt-2 text-sm text-red-600">{errors['dialect']}</p>}
            </div>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="payExpected" className="block text-sm font-medium text-gray-700">
              My hourly rate expectations*
            </label>
            <div className="mt-1">
              <div className="max-w-lg flex rounded-md shadow-sm">
              <span
                className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-600 sm:text-sm">
                  US $
                </span>
                <InputWithTag required value={values.payExpected} onBlur={handleBlur}
                              onChange={handleChange} type="text" name="payExpected"
                />
              </div>
              {errors['payExpected'] && <p className="mt-2 text-sm text-red-600">{errors['payExpected']}</p>}
            </div>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Verification
          </h3>
          <div className="sm:col-span-6">
            <label htmlFor="payExpected" className="block text-sm font-medium text-gray-700">
              Upload your teaching certificates if any.
            </label>
            <div className="sm:col-span-6">
              <div {...getRootProps()}
                   className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                       aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only"/>
                    {!values.file ? <>
                      <div
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                        <span>Upload files</span>
                      </div>
                      <p className="pl-1">or drag and drop</p>
                    </> : <div>{values.file.name}</div>}
                  </div>
                  {isUploading && <p className="text-xs text-gray-500">Uploading ...</p>}
                  {!isUploading && values.certificates.length > 0 && <p className="text-xs text-gray-500">{values.certificates.length} files uploaded</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Other
          </h3>
          <div className="sm:col-span-6">
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
              Where did you find us*
            </label>
            <div className="mt-1">
              <Input required value={values.trafficSource} onBlur={handleBlur}
                     onChange={handleChange} type="text" name="trafficSource"
              />
              {errors['trafficSource'] && <p className="mt-2 text-sm text-red-600">{errors['trafficSource']}</p>}
            </div>
          </div>
        </div>
      </div>

    </div>

    {/*Buttons*/}
    <div className="pt-5 w-full flex-1 mt-8">
      <div className="flex justify-center">
        <Button disabled={isUploading} type="submit"
        >
          {isUploading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                               fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>}
          {'Next'}
        </Button>
      </div>
    </div>
  </FormContainer>

};

const FormContainer = tw.form`
  w-full flex-1 mt-8
`;

const Button = tw.button`
  ml-3 inline-flex justify-center py-2 
  px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white 
  bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
`;

const Input = tw.input`
  shadow-sm focus:ring-indigo-500 
  focus:border-indigo-500 block w-full 
  sm:text-sm border-gray-300 rounded
`;

const InputWithTag = tw.input`
  flex-1 block w-full focus:ring-indigo-500 
  focus:border-indigo-500 min-w-0 rounded-none 
  rounded-r-md sm:text-sm border-gray-300
`;
