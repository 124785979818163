import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/features/TwoColWithButton";
import Faqs from "components/faqs/SingleCol.js";
import Features from "components/features/ThreeColSimple.js";

import Footer from "components/footers/SimpleFiveColumn.js";
import tw from "twin.macro";
import {Container} from "components/misc/Layouts.js";
import HeaderBase, {NavLinks, PrimaryLink} from "../components/headers/light";

export default () => (
    <AnimationRevealPage disabled>
      <Hero/>
      {/*<Features/>*/}
      <Footer/>
    </AnimationRevealPage>
);


const Column = tw.div`
  flex flex-col items-center 
`;

const PrimaryAction = tw.button`
  rounded-full px-8 py-3 text-sm 
  sm:text-base sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 
  bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:ring
`;