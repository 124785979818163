import React, {useEffect, useReducer, useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container as ContainerBase} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import logo from "../images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import {ReactComponent as SignUpIcon} from "feather-icons/dist/icons/zap.svg";
import Steps from "../components/misc/Steps";
import UpdateProfile from "../components/forms/UpdateProfile";
import UploadVideo from "../components/features/UploadVideo";
import AwaitApproval from "../components/features/AwaitApproval";
import {updateUser, useAuthDispatch, useAuthState} from "../context";
import {teacherStatus} from "../config/teacherStatus";

export default ({
                  logoLinkUrl = "#",
                  headingText = "Set up your profile",
                  submitButtonText = "Sign Up",

                }) => {
  const dispatch = useAuthDispatch()

  const userDetails = useAuthState();

  const pageSelector = (status) => {
    let value = Object.values(teacherStatus).indexOf(status);

    // TODO: fix
    if (value > 2) value = 2;

    return value === -1 ? 0 : value;
  }

  const reducer = (state, status) => {
    if (typeof status === 'number') return status;
    return pageSelector(status)
  }
  const [page, dispatchPage] = useReducer(reducer, userDetails.user.teacherStatus, pageSelector);

  const onProfileSubmit = async (payload) => {
    try {
      const res = await updateUser(dispatch, {
        user: userDetails,
        payload
      });
      dispatchPage(page + 1);
    } catch (err) {
      console.log(err);
    }
  }

  return <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo}/>
          </LogoLink>
          <MainContent>
            {page === 0 && <Heading>{headingText}</Heading>}
            {page !== 2 && <Steps currentStep={page}/>}
            {
              {
                0: <UpdateProfile
                  imgUrl={userDetails.user.imgUrl}
                  firstName={userDetails.user.firstName}
                  lastName={userDetails.user.lastName}
                  country={userDetails.user.country}
                  city={userDetails.user.city}
                  about={userDetails.user.about}
                  education={userDetails.user.education}
                  dialect={userDetails.user.dialect}
                  payExpected={userDetails.user.payExpected}
                  trafficSource={userDetails.user.trafficSource}
                  tier={userDetails.user.tier}
                  certificates={userDetails.user.certificates}
                  onSubmit={(value) => onProfileSubmit(value)}
                />,
                1: <UploadVideo onSubmit={(value) => onProfileSubmit(value)}/>,
                2: <AwaitApproval/>
              }[page]
            }
          </MainContent>
        </MainContainer>
      </Content>
    </Container>
  </AnimationRevealPage>

};


const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-9/12 xl:w-9/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
