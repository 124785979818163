import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container as ContainerBase} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import logo from "images/logo.png";
import Login from "../components/login";

export default (props) => {

  return <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href="/">
            <LogoImage src={logo}/>
          </LogoLink>
          <Login isSignUp={true}/>
        </MainContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
};


const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
