import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import {css} from "styled-components/macro";

import Header, {NavLink, NavLinks, PrimaryLink} from "../headers/light.js";

import {ReactComponent as SvgDecoratorBlob1} from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import TeamIllustrationSrc from "../../images/team-illustration-2.svg";
import {ReactComponent as SvgDotPattern} from "../../images/dot-pattern.svg";
import {SectionHeading, Subheading as SubheadingBase} from "../misc/Headings";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons";
import appleIconImageSrc from "../../images/apple-icon.png";
import googlePlayIconImageSrc from "../../images/google-play-icon.png";
import {navLinks} from "../misc/NavLinks";


export default ({
                  roundedHeaderButton = true,
                  subheading = "Our Expertise",
                  heading = (
                    <>
                      Practice the language instantly with students from other countries and <span tw="text-primary-500">native language</span> teachers
                    </>
                  ),
                  description = "Choose a topic and make a video call to have an immediate talk or a lesson.",
                  primaryButtonText = "Learn More",
                  primaryButtonUrl = "https://timerse.com",
                  imageSrc = TeamIllustrationSrc,
                  imageRounded = true,
                  imageBorder = false,
                  imageShadow = false,
                  imageCss = null,
                  imageDecoratorBlob = false,
                  imageDecoratorBlobCss = null,
                  textOnLeft = true,
                  link1Text = "App Store",
                  link1Url = "https://apps.apple.com/us/app/wellspeak/id1561375698",
                  link1IconSrc = appleIconImageSrc,
                  link2Text = "Google Play",
                  link2Url = "https://play.google.com/store/apps/details?id=com.wellspeakbot.mobile&hl=en_US&gl=US",
                  link2IconSrc = googlePlayIconImageSrc,
                }) => {

  return (
    <>
      <Header links={navLinks} roundedHeaderButton={roundedHeaderButton}/>
      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow}
                   imageRounded={imageRounded}/>
            {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss}/>}
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Subheading>{subheading}</Subheading>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <LinksContainer>
                <Link href={link1Url}>
                  <img src={link1IconSrc} alt=""/>
                  <span>{link1Text}</span>
                </Link>
                <Link href={link2Url}>
                  <img src={link2IconSrc} alt=""/>
                  <span>{link2Text}</span>
                </Link>
              </LinksContainer>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </>
  );
};



const Container = tw.div`relative`;
const LinksContainer = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`
const Link = styled.a`
  ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full 
  inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 
  bg-gray-100 hocus:bg-gray-300 text-gray-900 hocus:text-gray-900 shadow hover:shadow-lg 
  focus:ring focus:outline-none transition duration-300`}
  img {
    ${tw`inline-block h-8 mr-3`}
  }
  span {
    ${tw`leading-none inline-block`}
  }
`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-200`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);
