import React from "react";
import UserDefaultPhoto from "../misc/UserDefaultPhoto";
import Select from 'react-select'
import {teacherStatus} from "../../config/teacherStatus";
import {TEACHER_STATUS_SELECT} from "../../config/teacherStatusSelect";
import config from "../../config/config";
import {useAuthState} from "../../context";
import {TEACHER_TIER_SELECT} from "../../config/teacherTierSelect";
import {DIALECTS_LIST} from "../../config/dialectsSelect";
import {COUNTRIES_LIST} from "../../config/countriesSelect";


export default ({user, updateStatus}) => {

  let filteredCountry = COUNTRIES_LIST.filter(dialect => dialect.value === user.country);
  let filteredDialect = DIALECTS_LIST.filter(dialect => dialect.value === user.dialect);

  return (
    <article className="w-full">
      {/* Profile header */}
      <div>
        <div className="h-32 w-full object-cover lg:h-48 bg-indigo-500"
             alt=""/>
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              {user.imgUrl ? <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                                  src={user.imgUrl}
                                  alt=""/> :
                <UserDefaultPhoto className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"/>}
            </div>
            <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                {user.firstName && <h1 className="text-2xl font-bold text-gray-900 truncate">
                  {user.firstName} {user.lastName}
                </h1>}
              </div>
              <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                {/*<button type="button"*/}
                {/*        className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">*/}
                {/*  <svg className="-ml-1 mr-2 h-5 w-5 text-gray-400" data-todo-x-description="Heroicon name: solid/mail"*/}
                {/*       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
                {/*    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>*/}
                {/*    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>*/}
                {/*  </svg>*/}
                {/*  <span>Message</span>*/}
                {/*</button>*/}
                {/*<button type="button"*/}
                {/*        className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">*/}
                {/*  <svg className="-ml-1 mr-2 h-5 w-5 text-gray-400" data-todo-x-description="Heroicon name: solid/phone"*/}
                {/*       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
                {/*    <path*/}
                {/*      d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>*/}
                {/*  </svg>*/}
                {/*  <span>Call</span>*/}
                {/*</button>*/}
                {user.teacherStatus && <dd className="mt-1 text-sm text-gray-900 w-52">
                  <Select
                    value={TEACHER_STATUS_SELECT.filter(option => option.value === user.teacherStatus)}
                    options={TEACHER_STATUS_SELECT}
                    onChange={(value) => updateStatus(user.id, 'teacherStatus', value)}
                  />
                </dd>}
              </div>
            </div>
          </div>
          {user.firstName && <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
            <h1 className="text-2xl font-bold text-gray-900 truncate">
              {user.firstName} {user.lastName}
            </h1>
          </div>}
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="border-b border-gray-200">
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {/* Current: "border-pink-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
              <a href="#"
                 className="border-pink-500 text-gray-900 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                 aria-current="page">
                Profile
              </a>
              {/*<a href="/" className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">*/}
              {/*  Calendar*/}
              {/*</a>*/}
              {/*<a href="/" className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">*/}
              {/*  Recognition*/}
              {/*</a>*/}
            </nav>
          </div>
        </div>
      </div>

      {/* Description list */}
      <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Email
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {user.email}
            </dd>
          </div>


          {user.city && <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Location
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {user.city.trim()}, {filteredCountry.length > 0 && filteredCountry[0].label}
            </dd>
          </div>}


          {user.payExpected && <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Expected rate
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              ${user.payExpected}
            </dd>
          </div>}

          {filteredDialect.length > 0 && <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Dialect
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {filteredDialect[0].label}
            </dd>
          </div>}

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Tier
            </dt>
            <dd className="mt-1 text-sm text-gray-900 w-52">
              <Select
                value={TEACHER_TIER_SELECT.filter(option => option.value === user.tier)}
                options={TEACHER_TIER_SELECT}
                onChange={(value) => updateStatus(user.id, 'tier', value)}
              />
            </dd>
          </div>

          {user.education && <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              Education
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {user.education}
            </dd>
          </div>}


          {user.about && <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              About
            </dt>
            <dd className="mt-1 max-w-prose text-sm text-gray-900">
              {user.about}
            </dd>
          </div>}

          {user.certificates && user.certificates.length > 0 && <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              Verification
            </dt>
            {user.certificates.map((cert, index) => (
              <dd key={index} className="mt-1 max-w-prose text-sm text-gray-900">
                <a target='_blank' href={cert}>Link {index + 1}</a>
              </dd>))}
          </div>}


          {user.videoUrl && <div className="sm:col-span-2 mb-10">
            <dt className="text-sm font-medium text-gray-500">
              Video
            </dt>
            <dd className="mt-1 max-w-prose text-sm text-gray-900">
              <video key={user.videoUrl} width="320" height="240" controls preload={'metadata'}>
                <source src={user.videoUrl} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </dd>
          </div>}
        </dl>
      </div>
    </article>
  );
}
