import config from '../config/config';

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({type: 'REQUEST_LOGIN'});
    let response = await fetch(`${config.API_BASE_URL}/auth/login`, requestOptions);
    let data = await response.json();
    if (data.user) {
      dispatch({type: 'LOGIN_SUCCESS', payload: data});
      localStorage.setItem('currentUser', JSON.stringify(data));

      setTimeout(function() {
        refreshToken(dispatch)
      }, Math.abs(new Date(data.tokens.access.expires).getTime()  - new Date().getTime()) - 10000);

      return data;
    }

    dispatch({type: 'LOGIN_ERROR', error: data});
    console.log(data);
    return;
  } catch (error) {
    dispatch({type: 'LOGIN_ERROR', error: error});
    console.log(error);
  }
}


export async function registerUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(loginPayload),
  };

  try {
    dispatch({type: 'REQUEST_LOGIN'});
    let response = await fetch(`${config.API_BASE_URL}/auth/register`, requestOptions);
    let data = await response.json();
    if (data.user) {
      dispatch({type: 'LOGIN_SUCCESS', payload: data});
      localStorage.setItem('currentUser', JSON.stringify(data));

      setTimeout(function() {
        refreshToken(dispatch)
      }, Math.abs(new Date(data.tokens.access.expires).getTime()  - new Date().getTime()) - 10000);

      return data;
    }

    dispatch({type: 'LOGIN_ERROR', error: data});
    console.log(data);
    return;
  } catch (error) {
    dispatch({type: 'LOGIN_ERROR', error: error});
    console.log(error);
  }
}

export async function refreshToken(dispatch) {
  console.log('refreshToken');
  let user = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser'))
    : '';

  console.log(user);
  if (!user || new Date(user.tokens.refresh.expires) < new Date()) {
    await logout(dispatch);
    return;
  }

  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({refreshToken: user.tokens.refresh.token}),
  };

  try {
    let response = await fetch(`${config.API_BASE_URL}/auth/refresh-tokens`, requestOptions);
    let data = await response.json();

    if (data.access && data.refresh) {
      dispatch({type: 'TOKEN_REFRESH_SUCCESS', payload: data});
      localStorage.setItem('currentUser', JSON.stringify({user: user.user, tokens: data}));

      setTimeout(function() {
        refreshToken(dispatch)
      }, Math.abs(new Date(data.access.expires).getTime()  - new Date().getTime()) - 10000);

      return data;
    }

    console.log(data);
    await logout(dispatch);
    return;
  } catch (error) {
    console.log(error);
    await logout(dispatch);
  }
}

export async function updateUser(dispatch, {user, payload}) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.tokens.access.token}`
    },
    body: JSON.stringify(payload),
  };

  try {
    dispatch({type: 'UPDATE_USER'});
    let response = await fetch(`${config.API_BASE_URL}/users/${user.user.id}`, requestOptions);
    let data = await response.json();
    let existing = localStorage.getItem('currentUser');
    if (data.email) {
      localStorage.setItem('currentUser', JSON.stringify({tokens: JSON.parse(existing).tokens, user: data}));
      dispatch({type: 'UPDATE_SUCCESS', payload: data});
      return data;
    }

    dispatch({type: 'LOGIN_ERROR', error: data});
    console.log(data);
  } catch (error) {
    dispatch({type: 'UPDATE_ERROR', error: error});
    console.log(error);
  }

}


export async function logout(dispatch) {
  console.log('logout');
  dispatch({type: 'LOGOUT'});
  localStorage.removeItem('currentUser');
  localStorage.removeItem('tokens');
}
