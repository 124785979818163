import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuthState } from '../context';
import Sidebar from "../pages/manage/Sidebar";

const AppRoutes = ({ component: Component, path, isPrivate, ...rest }) => {
  const userDetails = useAuthState();
  return (
    <Route
      path={path}
      render={(props) =>
        (!userDetails.tokens || userDetails.user.role !== 'admin') ? (
          <Redirect to={{ pathname: '/login' }} />
        ) : (
          <Sidebar><Component {...props} /></Sidebar>
        )
      }
      {...rest}
    />
  );
};

export default AppRoutes;
