import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import Alert from "../../components/misc/Alert";
import validation from "../../services/validation";
import { loginUser, refreshToken, useAuthState, useAuthDispatch, logout } from "../../context";
import { useHistory } from "react-router-dom";
import { registerUser } from "../../context/actions";


export default ({ isSignUp = false }) => {
  let history = useHistory();

  const dispatch = useAuthDispatch();
  const { loading, errorMessage, tokens } = useAuthState();

  const [values, setValues] = useState({
    email: "",
    password: "",
    password1: ""
  });

  //  Redirect if already logged in
  useEffect(() => {
    if (tokens && tokens.access && new Date(tokens.access.expires) > new Date()) history.push('/profile');
  }, []);

  const [errors, setErrors] = useState({});

  const [touched, setTouched] = React.useState({});

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }))

    setTouched({
      ...touched,
      [name]: true,
    });
  }

  const handleBlur = evt => {
    const { name, value } = evt.target;
    const { [name]: removedError, ...rest } = errors;
    const error = validation[name](value);

    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error }),
    });

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (Object.values(errors).length > 0 || values.email === undefined || values.password === undefined) return;

    try {

      const res =  isSignUp ? await registerUser(dispatch, {
        email: values.email,
        password: values.password,
      }) : await loginUser(dispatch, {
        email: values.email,
        password: values.password,
      });

      if (res === undefined || !res.user) return;

      if (res.user.role === 'admin') return history.push('/manage');
      history.push('/profile');

    } catch (err) {
      console.log(err);
    }
  }


  return <Container>
    <Heading>{isSignUp ? "Sign up to start teaching" : "Log in"}</Heading>
    <FormContainer>
      {errorMessage && <Alert errorText={errorMessage.message}/>}
      <Form onSubmit={onSubmit}>
        <Input onBlur={handleBlur} type="email" placeholder="Email" onChange={handleChange} name="email"
               value={values.email} required/>
        {errors['email'] && <p className="mt-2 text-sm text-red-600">{errors['email']}</p>}
        <Input onBlur={handleBlur} type="password" placeholder="Password" onChange={handleChange}
               name="password" value={values.password} required/>
        {errors['password'] && <p className="mt-2 text-sm text-red-600">{errors['password']}</p>}
        <SubmitButton type="submit">
          {isSignUp && <SignUpIcon className="icon"/>}
          <span className="text">{isSignUp ? "Sign Up" : "Login"}</span>
        </SubmitButton>
        {isSignUp && <p tw="mt-6 text-xs text-gray-600 text-center">
          I agree to abide by WellSpeak's{" "}
          <a href={"/"} tw="border-b border-gray-500 border-dotted">
            Terms of Service
          </a>{" "}
          and its{" "}
          <a href={"/"} tw="border-b border-gray-500 border-dotted">
            Privacy Policy
          </a>
        </p>}
      </Form>
    </FormContainer>
  </Container>
};


const Container = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:ring focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }

  .text {
    ${tw`ml-3`}
  }
`;
