import React from 'react';
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Profile from "../pages/Profile";
import Dashboard from "../pages/manage/Dashboard";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import NotFound from "../pages/NotFound";
import Teach from "../pages/Teach";
import Lessons from "../pages/manage/Lessons";
import Teachers from "../pages/manage/Teachers";

const routes = [
  {
    path: '/manage/lessons',
    component: Lessons,
    isPrivate: true,
  },
  {
    path: '/manage/teachers',
    component: Teachers,
    isPrivate: true,
  },
  {
    path: '/manage',
    component: Dashboard,
    isPrivate: true,
  },
  {
    path: '/*',
    component: NotFound,
    isPrivate: false,
  },
];

export default routes;
