const teacherStatus = {
  PROFILE_INCOMPLETE: 'PROFILE_INCOMPLETE',
  VIDEO_REQUIRED: 'VIDEO_REQUIRED',
  AWAIT_APPROVAL: 'AWAIT_APPROVAL',
  DENIED: 'DENIED',
  APPROVED: 'APPROVED',
};

module.exports = {
  teacherStatus,
};
