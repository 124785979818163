import React, {useCallback, useState} from "react";
import tw from "twin.macro";
import VideoRecorder from 'react-video-recorder'
import {useDropzone} from 'react-dropzone'
import validation from "../../services/validation";
import Alert from "../misc/Alert";
import {b64toFormData, uploadFile} from '../../services/upload';
import {useAuthState} from "../../context";

export default ({onSubmit: onOuterSubmit}) => {
  const userDetails = useAuthState();

  const [isBlob, setBlob] = useState(true);
  const [alert, setAlert] = useState('');
  const [isUploading, setUploading] = useState(false);

  const [values, setValues] = useState({
    file: null,
    blob: null,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!values.file && !values.blob) {
      setAlert('Record video or select file');
      return;
    };

    let videoUrl = '';
    try {
      setUploading(true);
      if (values.file) {
        videoUrl = await uploadFile(values.file, userDetails);
      } else {
        let fd = new FormData();
        fd.append('file', values.blob, 'video.webm');
        videoUrl = await uploadFile(fd.get('file'), userDetails);
      }
      onOuterSubmit && onOuterSubmit({videoUrl, teacherStatus: 'AWAIT_APPROVAL'});
    } catch (err) {
      setAlert(err.message);
    }

    setUploading(false);
  }


  const onDrop = useCallback(acceptedFiles => {
    console.log('onDrop');
    setValues({
      blob: null,
      file: acceptedFiles[0],
    })
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    accept: 'video/*',
    maxFiles: 1
  })


  return <FormContainer onSubmit={onSubmit}>
    {alert && <Alert errorText={alert}/>}
    <div className="space-y-8">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Introduce yourself with a video
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Let students know what they can expect from a lesson with you by recording a video highlighting your teaching
          style, expertise and personality. Students can be nervous to speak with a foreigner, so it really helps to
          have a friendly video that introduces yourself and invites students to call you.
        </p>
      </div>


      <div className="sm:block">
        <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">

          <button
            onClick={(e) => {
              e.preventDefault();
              setBlob(true);
            }}
            aria-current="page"
            className={isBlob ? ActiveTab : inActiveTab}>
            <span>Record a Video</span>
            <span aria-hidden="true"
                  className={`${!isBlob ? `bg-transparent` : `bg-indigo-500`} absolute inset-x-0 bottom-0 h-0.5`}></span>

          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setBlob(false);
            }}
            aria-current="false"
            className={!isBlob ? ActiveTab : inActiveTab}>
            <span>Upload a File</span>
            <span aria-hidden="true"
                  className={`${isBlob ? `bg-transparent` : `bg-indigo-500`} absolute inset-x-0 bottom-0 h-0.5`}></span>
          </button>

        </nav>
      </div>

      {isBlob ? <VideoRecorder
        showReplayControls={true}
        isOnInitially={true}
        constraints={{
          audio: true,
          video: true
        }}
        onRecordingComplete={blob => {
          setValues(p => ({
            file: null,
            blob
          }))
        }}
      /> : <div className="sm:col-span-6">
        <div {...getRootProps()}
             className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                 aria-hidden="true">
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            <div className="flex text-sm text-gray-600">
              <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only"/>
              {!values.file ? <>
                <div
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                  <span>Upload a file</span>
                </div>
                <p className="pl-1">or drag and drop</p>
              </> : <div>{values.file.name}</div>}
            </div>
            {!values.file && <p className="text-xs text-gray-500">
              MP4, MOV, AVI, etc.
            </p>}
          </div>
        </div>
      </div>}
    </div>

    {/*Buttons*/}
    <div className="pt-5 w-full flex-1 mt-8">
      <div className="flex justify-center">
        <Button type="submit"
        >
          {isUploading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                               fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>}
          {'Upload & Continue'}
        </Button>
      </div>
    </div>
  </FormContainer>

};

const ActiveTab = `text-gray-900 rounded-l-lg  group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10`
const inActiveTab = `text-gray-500 hover:text-gray-700   group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10`

const Button = tw.button`
  ml-3 inline-flex justify-center py-2 
  px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white 
  bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
`;

const FormContainer = tw.form`w-full flex-1 mt-8`;
