const TEACHER_STATUS_SELECT = [
  { value: 'PROFILE_INCOMPLETE', label: 'Profile Incomplete' },
  { value: 'VIDEO_REQUIRED', label: 'Video Required' },
  { value: 'AWAIT_APPROVAL', label: 'Awaiting Approval' },
  { value: 'DENIED', label: 'Denied' },
  { value: 'APPROVED', label: 'Approved' },
];

module.exports = {
  TEACHER_STATUS_SELECT,
};
