import React, {useEffect, useState} from "react";
import Profile from "../../components/manage/Profile";
import config from "../../config/config";
import {useAuthState} from "../../context";
import UserDefaultPhoto from "../../components/misc/UserDefaultPhoto";

export default (props) => {

  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(0);
  const [filter, setFilter] = useState('');

  const userDetails = useAuthState();
  let previousLetter = null;
  let currentLetter = null;

  useEffect(() => {
    const fetchData = async () => {

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userDetails.tokens.access.token}`
        },
      };

      let response = await fetch(`${config.API_BASE_URL}/users?limit=10000&sortBy=firstName:asc`, requestOptions);
      let data = await response.json();
      setUsers(data.results);
    }
    fetchData();
  }, []);

  const updateStatus = async (userId, name, value) => {
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userDetails.tokens.access.token}`
      },
      body: JSON.stringify({
        [name]: value.value,
      })
    };

    await fetch(`${config.API_BASE_URL}/users/${userId}`, requestOptions)
      .then((response) => {
        if (response.ok) {
          let index = users.findIndex(user => user.id === userId);
          if (index != -1) {
            setUsers(prev => {
              let array = prev;
              array[index][name] = value.value;
              return [...array];
            })
          }
          //return response.json();
        } else {
          alert('Something went wrong. Please reload the page.');
        }
      }).catch(err => {
        alert(err.message);
      })
  }

  let filteredUsers = filter.length > 2 ? users.filter(user => `${user.firstName}${user.lastName}`.toLowerCase().includes(filter)) : filter.length === 0 ? users : [];


  return <div className="flex-1 relative z-0 flex overflow-hidden">
    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last" tabIndex={0}
          data-todo-x-data="" data-todo-x-init="$el.focus()">
      {/* Breadcrumb */}
      <nav className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
        <a href="#" className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
          <svg className="-ml-2 h-5 w-5 text-gray-400"
               data-todo-x-description="Heroicon name: solid/chevron-left" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"></path>
          </svg>
          <span>Teachers Directory</span>
        </a>
      </nav>

      {filteredUsers.length > 0 && filteredUsers[currentUser] &&
      <Profile user={filteredUsers[currentUser]} updateStatus={updateStatus}/>}
    </main>
    <aside className="hidden xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
      <div className="px-6 pt-6 pb-4">
        <h2 className="text-lg font-medium text-gray-900">Teachers Directory</h2>
        <p className="mt-1 text-sm text-gray-600">
          Search {users.length} teachers
        </p>
        <form className="mt-6 flex space-x-4" action="#" onSubmit={(e) => e.preventDefault()}>
          <div className="flex-1 min-w-0">
            <label htmlFor="search" className="sr-only">Search</label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                {/* Heroicon name: mail */}
                <svg className="h-5 w-5 text-gray-400" data-todo-x-description="Heroicon name: solid/search"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                     aria-hidden="true">
                  <path fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"></path>
                </svg>
              </div>
              <input type="search" name="search" id="search"
                     autoComplete="off"
                     className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                     placeholder="Search" onChange={(val) => setFilter(val.target.value)}/>
            </div>
          </div>
          {/*<button type="submit"*/}
          {/*        className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">*/}
          {/*  <svg className="h-5 w-5 text-gray-400" data-todo-x-description="Heroicon name: solid/filter"*/}
          {/*       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">*/}
          {/*    <path fillRule="evenodd"*/}
          {/*          d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"*/}
          {/*          clipRule="evenodd"></path>*/}
          {/*  </svg>*/}
          {/*  <span className="sr-only">Search</span>*/}
          {/*</button>*/}
        </form>
      </div>
      {/* Directory list */}
      <nav className="flex-1 min-h-0 relative overflow-y-auto" aria-label="Directory">
        <ul className="relative z-0 divide-y divide-gray-200">

          {filteredUsers.map((user, index) => {
            currentLetter = user.firstName ? user.firstName[0].toUpperCase() : null;
            let addHeading = currentLetter !== previousLetter;
            previousLetter = currentLetter;
            return (
              <React.Fragment key={index}>
                {addHeading && <div
                  className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                  <h3>{user.firstName[0]}</h3>
                </div>}
                <li onClick={() => setCurrentUser(index)}>
                  <div
                    className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500">
                    <div className="flex-shrink-0">
                      {user.imgUrl ? <img className="h-10 w-10 rounded-full"
                                          src={user.imgUrl}
                                          alt=""/> : <UserDefaultPhoto className="h-10 w-10 rounded-full"/>}
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="focus:outline-none">
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true"></span>
                        <p className="text-sm font-medium text-gray-900">
                          {user.firstName || 'Guest'} {user.lastName && user.lastName}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          {user.teacherStatus}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      </nav>
    </aside>
  </div>
};

