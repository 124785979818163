import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Alert from "../../components/misc/Alert";
import validation from "../../services/validation";
import config from "../../config/config";
import { useLocation } from 'react-router-dom'
import queryString from 'query-string';

export default () => {
  const { search } = useLocation();
  const query = queryString.parse(search);

  const [values, setValues] = useState({
    password: "",
    password1: ""
  });

  const [isSuccessFull, setIsSuccessFull] = useState(false);

  const [globalError, setGlobalError] = useState(null);

  const [errors, setErrors] = useState({});

  const [touched, setTouched] = React.useState({});

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }))

    setTouched({
      ...touched,
      [name]: true,
    });
  }

  const handleBlur = evt => {
    const { name, value } = evt.target;
    const { [name]: removedError, ...rest } = errors;
    const error = validation[name](value);

    if ((values.password !== '' && values.password1 !== '') && values.password !== values.password1) {
      setGlobalError('Passwords do not match');
    } else {
      setGlobalError(null);
    }

    setErrors({
      ...rest,
      ...(error && { [name]: touched[name] && error }),
    });

    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (Object.values(errors).length > 0 || values.password === undefined || values.password !== values.password1) return;

    try {
      const res = await resetPassword(query.token, values.password);
      setIsSuccessFull(true);
    } catch (err) {
      setGlobalError(err.message);
    }
  }

  async function resetPassword(token, password) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password,
      }),
    };
    let response = await fetch(`${config.API_BASE_URL}/auth/reset-password?token=${token}`, requestOptions);
    if (response.status !== 204) {
      let data = await response.json();
      throw new Error(data.message);
    }
  }

  if (isSuccessFull) {
    return <Container>
      <Heading>{"Your password has been reset"}</Heading>
    </Container>
  }


  return <Container>
    <Heading>{"Reset your password"}</Heading>
    <FormContainer>
      {globalError && <Alert errorText={globalError}/>}
      {!query.token && <Alert errorText={'Invalid token. Try to reset you password again.'}/>}
      {query.token && <Form onSubmit={onSubmit}>
        <Input onBlur={handleBlur} type="password" placeholder="Password" onChange={handleChange}
               name="password" value={values.password} required/>
        {errors['password'] && <p className="mt-2 text-sm text-red-600">{errors['password']}</p>}
        <Input onBlur={handleBlur} type="password" placeholder="Password confirmation" onChange={handleChange}
               name="password1" value={values.password1} required/>
        {errors['password1'] && <p className="mt-2 text-sm text-red-600">{errors['password1']}</p>}
        <SubmitButton type="submit">
          <span className="text">{"Save"}</span>
        </SubmitButton>
      </Form>}
    </FormContainer>
  </Container>
}
;


const Container = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:ring focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }

  .text {
    ${tw`ml-3`}
  }
`;
