import React, {useEffect, useState} from "react";
import {css} from "styled-components/macro"; //eslint-disable-line
import routes from './config/routes.js';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import AppRoute from './components/AppRoutes';
import Index from "./pages/Index";
import Sidebar from "./pages/manage/Sidebar";
import manageRoutes from "./config/manageRoutes";
import ManageAppRoute from "./components/ManageAppRoute";
import {refreshToken, useAuthDispatch, useAuthState} from "./context";
import {isDesktop} from "react-device-detect";

export default function App() {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();

  const [refreshed, setRefreshed] = useState(false);

  useEffect( () => {
    if (userDetails.tokens) {
       refreshToken(dispatch).then(() => setRefreshed(true));
    } else {
      setRefreshed(true);
    }
  }, []);

  if (!refreshed) {
    return  <div className="flex h-screen justify-center items-center"><svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="blue"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg></div>
  }

  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Index}/>
        {routes.map((route) => (
          <AppRoute
            key={route.path}
            path={route.path}
            component={route.component}
            isPrivate={route.isPrivate}
          />
        ))}
        {manageRoutes.map((route) => (
          <ManageAppRoute
            key={route.path}
            path={route.path}
            component={route.component}
            isPrivate={route.isPrivate}
          />
        ))}
      </Switch>
    </Router>
  );
}
