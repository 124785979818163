const DIALECTS_LIST = [
  {value: 'NAN', label: 'I\'m not a native speaker'},
  {value: 'NA', label: 'North America'},
  {value: 'CA', label: 'Canada'},
  {value: 'US', label: 'USA'},
  {value: 'SU', label: 'Southern USA'},
  {value: 'UK', label: 'United Kingdom'},
  {value: 'IR', label: 'Ireland'},
  {value: 'SC', label: 'Scotland'},
  {value: 'SA', label: 'South Africa'},
  {value: 'AU', label: 'Australia'},
  {value: 'NZ', label: 'New Zealand'},
  {value: 'PH', label: 'Philippines'},
  {value: 'OT', label: 'Other'},
];


module.exports = {
  DIALECTS_LIST
}
