import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Faqs from "components/faqs/SingleCol.js";
import Features from "components/features/DashedBorderSixFeatures.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import tw from "twin.macro";
import {Container} from "components/misc/Layouts.js";
import styled from "styled-components";
import Header, {
  DesktopNavLinks,
  LogoLink,
  NavLink,
  NavToggle,
  PrimaryLink as PrimaryLinkBase

} from "../components/headers/light";
import {navLinks} from "../components/misc/NavLinks";
import MainImage from '../images/teach-hero.jpeg';

export default () => {

  return <AnimationRevealPage>
    <TopContainer>
      <OpacityOverlay/>
      <HeroContainer>
        <StyledHeader links={navLinks}/>
        <Content>
          <Heading>
            Teach English
            <br/>
            on demand
          </Heading>
          <PrimaryAction href="/signup">Get Started</PrimaryAction>
        </Content>
      </HeroContainer>
    </TopContainer>
    <Features/>
    <Column>
      <PrimaryAction href="/signup">Get Started</PrimaryAction>
    </Column>
    <Faqs/>
    <Container>
      <Column>
        <PrimaryAction href="/signup">Get Started</PrimaryAction>
      </Column>
    </Container>
    <Footer/>
  </AnimationRevealPage>

}

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }

  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const TopContainer = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover min-h-full`}
  background-image: url(${MainImage});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-10`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug mt-24`}
  span {
    ${tw`inline-block mt-2`}
  }
`;



const Column = tw.div`
  flex flex-col 
  items-center 
`;

const PrimaryAction = tw.a`
  rounded-full m-14 px-8 py-3 text-sm sm:text-base sm:px-8 sm:py-4 
  bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 
  hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:ring
`;
