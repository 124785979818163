const prod = {
  API_BASE_URL: 'https://langbot.ru/v1',
};

const dev = {
  API_BASE_URL: 'https://api.tutobot.com/v1',
};

const config = process.env.NODE_ENV === 'production'
  ? prod
  : dev;

export default {
  ...config
};
