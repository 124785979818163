import {NavLinks, PrimaryLink} from "../headers/light";
import React from "react";
import tw from "twin.macro";

const buttonRounded = true;
// The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
const buttonRoundedCss = buttonRounded && tw`rounded-full`;

const navLinks = [
  <NavLinks key={1}>
    {/*<NavLink href="/#">About</NavLink>*/}
    {/*<NavLink href="/#">Blog</NavLink>*/}
    {/*<NavLink href="/#">Pricing</NavLink>*/}
    {/*<NavLink href="/#">Contact Us</NavLink>*/}
    {/*<NavLink href="/#">Testimonials</NavLink>*/}
  </NavLinks>,
  <NavLinks key={2}>
    <PrimaryLink css={buttonRoundedCss} href="/teach">
      For Teachers
    </PrimaryLink>
  </NavLinks>
];



export {
  navLinks
}