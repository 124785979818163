import React from 'react';
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import Signup from "../pages/Signup";
import Profile from "../pages/Profile";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import NotFound from "../pages/NotFound";
import Teach from "../pages/Teach";

const routes = [
  {
    path: '/login',
    component: Login,
    isPrivate: false,
  },
  {
    path: '/teach',
    component: Teach,
    isPrivate: false,
  },
  {
    path: '/signup',
    component: Signup,
    isPrivate: false,
  },
  {
    path: '/profile',
    component: Profile,
    isPrivate: true,
  },
  {
    path: '/policy',
    component: PrivacyPolicy,
    isPrivate: false,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    isPrivate: false,
  }
];

export default routes;
